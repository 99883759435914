// Styles imports
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css'

import '@/assets/styles/css/main.css'
import '@/assets/styles/scss/index.scss'

// fontawesome icons
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'

import { createAuth0 } from '@auth0/auth0-vue'
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import piniaPluginPersistedState from 'pinia-plugin-persistedstate'
import { createHead } from '@unhead/vue'
import { MotionPlugin } from '@vueuse/motion'
import { createBootstrap } from 'bootstrap-vue-next'
import { tooltipDirective } from '@/directives/tooltipDirective'
import clickOutside from '@/directives/click-outside'
import { swipeTriggerDirective } from '@/directives/swipeTriggerDirective'

import initializeSegment from '@/utils/segment/segment-script.js'
import { initializeSentry, logSentryError } from '@/utils/tracking/sentry'

import App from './App.vue'
import router from './router'
import { isMobileDevice, isPwaInstalled, isIos } from './helpers/mobile-device-helpers'

// Set the data values
let dataAttributes = {}
if (import.meta.env.MODE === 'development') {
  // Only import this module in development mode
  // const module = await import('@/data/dummy-data/home-data.json')
  // dataAttributes = module.default
}

const app = createApp(App, dataAttributes)

// Add Sntry Error monitoring to frontend
initializeSentry(app, router)

// Attach siteSettings to the Vue prototype
if (import.meta.env.MODE === 'development') {
  // only import this module in development mode
  const module = await import('@/data/dummy-data/site-settings.json')
  app.config.globalProperties.$siteSettings = {
    ...module.default,
    isMobileDevice: isMobileDevice(),
    // isPwaInstalled: true,
    isPwaInstalled: isPwaInstalled(window),
    isIosDevice: isIos(window),
    version: '0.0.1-Alpha-0.24.0'
  }
} else {
  app.config.globalProperties.$siteSettings = {
    apiBaseDomain: import.meta.env.VITE_API_BASE_DOMAIN,
    isMobileDevice: isMobileDevice(),
    // isPwaInstalled: true,
    isPwaInstalled: isPwaInstalled(window),
    isIosDevice: isIos(window),
    version: '0.0.1-Alpha-0.24.0'
  }
  // Add segment to project
  initializeSegment(import.meta.env.VITE_SEGMENT_TOKEN)
}

// Setup and use Auth0 integration
const auth0 = {
  domain: import.meta.env.VITE_AUTH0_DOMAIN,
  clientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
  callbackUrl: import.meta.env.VITE_AUTH0_CALLBACK_URL,
  audience: import.meta.env.VITE_AUTH0_AUDIENCE,
  scopes: 'openid profile email admin player offline_access',
  useRefreshTokens: true, // Enable refresh token rotation
  cacheLocation: 'localstorage' // Store tokens in localStorage
}

if (auth0?.domain && auth0?.clientId && auth0?.callbackUrl && auth0?.scopes && auth0?.audience) {
  app.use(
    createAuth0({
      domain: auth0.domain,
      clientId: auth0.clientId,
      authorizationParams: {
        redirect_uri: auth0.callbackUrl,
        scope: auth0.scopes
      },
      useRefreshTokens: auth0.useRefreshTokens,
      cacheLocation: auth0.cacheLocation
    })
  )
} else {
  logSentryError('Required Auth0 siteSettings values are missing')
}

// Setup and use Pinia for state management
const pinia = createPinia()
pinia.use(piniaPluginPersistedState)
app.use(pinia)

// attained from: https://unhead.unjs.io/setup/vue/installation
const head = createHead()
app.use(head)

app.use(router)

// Bootstrap
app.use(createBootstrap())

// attained from https://motion.vueuse.org/getting-started/introduction
app.use(MotionPlugin)

// Register directives
// Use the tooltip directive with your Vue application
app.directive('tooltip', tooltipDirective)
app.directive('click-outside', clickOutside)
app.directive('swipe-trigger', swipeTriggerDirective)

app.mount('#app')

if ('serviceWorker' in navigator) {
  const serviceWorkerUrl =
    import.meta.env.MODE === 'production' ? '/service-worker.js' : '/dev-dist/dev-service-worker.js'
  const serviceWorkerType = import.meta.env.MODE === 'production' ? 'classic' : 'module'
  try {
    navigator.serviceWorker
      .register(serviceWorkerUrl, {
        type: serviceWorkerType
      })
      .then((registration) => {
        console.log('Service worker registered')
      })
      .catch((error) => {
        logSentryError(error)
      })
  } catch (error) {
    logSentryError(error)
  }
}
