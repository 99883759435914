import { ref } from 'vue'
import { isPwaInstalled } from '@/helpers/mobile-device-helpers'
import { logSentryError } from '@/utils/tracking/sentry'

export const useNotificationsComposable = () => {
  const badgeNotification = ref({
    count: 0,
    lastUpdatedAt: null
  })
  let serviceWorkerMessageListener = null

  const incrementBadgeNotification = () => {
    badgeNotification.value.count++
    setBadge(badgeNotification.value.count)
  }

  const decrementBadgeNotification = () => {
    badgeNotification.value.count--
    setBadge(badgeNotification.value.count)
  }

  const resetBadgeNotification = () => {
    badgeNotification.value.count = 0
    clearBadge()
  }

  const requestNotificationPermission = async () => {
    if (!('Notification' in window)) {
      console.warn('This browser does not support notifications.')
      return 'unsupported'
    }

    const permission = await Notification.requestPermission()
    if (permission === 'granted') {
      console.log('Notification permission granted.')
      // You can now use the Badging API
    } else {
      console.log('Notification permission denied.')
    }
  }

  const checkNotificationPermission = () => {
    if (!('Notification' in window)) {
      console.warn('This browser does not support notifications.')
      return 'unsupported'
    }

    const permission = Notification.permission
    if (permission === 'granted') {
      console.log('Notification permission already granted.')
      // You can now use the Badging API
    } else if (permission === 'denied') {
      console.log('Notification permission already denied.')
    } else {
      console.log('Notification permission not yet requested.')
      requestNotificationPermission()
    }
  }

  const setBadge = async (count) => {
    if ('setAppBadge' in navigator) {
      try {
        await navigator.setAppBadge(count)
      } catch (error) {
        logSentryError(error)
      }
    }
  }

  const clearBadge = async () => {
    if ('clearAppBadge' in navigator) {
      try {
        await navigator.clearAppBadge()
      } catch (error) {
        logSentryError(error)
      }
    }
  }

  const determineBadgeCount = (data) => {
    // Process the data to compute the badge count
    // This is a placeholder implementation
    return data.count || 0
  }

  const setupServiceWorkerListener = () => {
    // if (isPwaInstalled(window)) {
    // Listen for messages from the service worker
    if ('serviceWorker' in navigator) {
      serviceWorkerMessageListener = (event) => {
        const { type, payload } = event.data
        if (type === 'incrementBadge') {
          incrementBadgeNotification()
        } else if (type === 'decrementBadge') {
          decrementBadgeNotification()
        } else if (type === 'resetBadge') {
          resetBadgeNotification()
        } else if (type === 'setBadge') {
          setBadge(payload.count)
          if (payload.count === 0) {
            clearBadge()
          }
        } else if (type === 'clearBadge') {
          clearBadge()
        }
      }
      navigator.serviceWorker.addEventListener('message', serviceWorkerMessageListener)
    }
    // }
  }

  const cleanupServiceWorkerListener = () => {
    if (serviceWorkerMessageListener && 'serviceWorker' in navigator) {
      navigator.serviceWorker.removeEventListener('message', serviceWorkerMessageListener)
    }
  }

  return {
    badgeNotification,
    incrementBadgeNotification,
    decrementBadgeNotification,
    resetBadgeNotification,
    requestNotificationPermission,
    checkNotificationPermission,
    setBadge,
    clearBadge,
    setupServiceWorkerListener,
    cleanupServiceWorkerListener
  }
}
